import React from 'react'
import Cards from '../../components/cards/Cards'

const Article = () => {
  return (
    <section className='py-4'>
      <Cards/>
    </section>
  )
}

export default Article