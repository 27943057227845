import React from 'react'
import Cards from '../../components/cards/Cards'
import Imagen from '../../components/imagen/Imagen'
import "./home.css"

const Home = () => {
  return (
    <div className='home container'>
      <section className="home-presentacion">
        <Imagen tipo="mariano0" clase="home-presentacion-imagen" />
        <div className="home-presentacion-texto">
          <p className='fw-bold fs-1 m-0 mt-3 mt-lg-0'>Sobre mi 👨🏾‍💻</p>
          <p className=''>Hola a todos y bienvenidos a mi blog. Mi nombre es Mariano y soy originario de Catamarca, Argentina. Actualmente vivo en Córdoba, donde estudio Ingeniería en Sistemas de la Información y programación orientada al desarrollo web (de manera autodidacta). En mi tiempo libre, disfruto mucho de la fotografía, los viajes, la naturaleza, el fútbol y soy fanatico incondicional de Boca Juniors. En este blog, quiero compartir mis experiencias y reflexiones sobre estos temas, así como también ofrecer información útil y recursos relacionados con la tecnología y el desarrollo web. Espero que encuentren algo interesante y útil en estas páginas y, como siempre, estaré encantado de recibir sus comentarios y sugerencias. ¡Gracias por visitar mi blog!</p>
        </div>
      </section>

      <Cards/>

    </div>
  )
}

export default Home