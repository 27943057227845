import React from 'react'

const Footer = () => {
  return (
    <footer>
        <div className="bg-dark text-white d-flex flex-column align-items-center py-3">
            <p className='mb-1'>Mis redes sociales</p>
            <ul className='list-unstyled d-flex gap-3 m-0'>
                <li><a className='text-decoration-none ' href="https://twitter.com/marianobazn" target="_blank" ><i className="fa-brands fa-square-twitter text-white fs-1"></i></a></li>
                <li><a className='text-decoration-none ' href="https://instagram.com/marianobazn" target="_blank" ><i className="fa-brands fa-square-instagram text-white fs-1"></i></a></li>
            </ul>
        </div>
    </footer>
  )
}

export default Footer