import './App.css'
import Rutas from './components/routes/Routes'

function App() {

  return (
    <div className="">
      <Rutas />
    </div>
  )
}

export default App
