import React from 'react'
import { NavLink, Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <nav className='navbar navbar-expand-lg navbar-dark bg-dark py-2 py-lg-0'>
      <div className="container-fluid justify-content-lg-center flex-lg-column">
        <Link to="/" className="navbar-brand fs-1 fw-bold">MARIANO BAZAN</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id='navbarSupportedContent'>
          <ul className='navbar-nav mb-3 gap-3'>
            <li className='nav-item'>
              <NavLink to="/" className="nav-link ps-0 py-0" aria-current="page">Inicio</NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to="/Articulos" className="nav-link py-0">Articulos</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar