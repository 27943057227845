import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import { useQuill } from "react-quilljs"
import 'quill/dist/quill.snow.css'

const ArticleView = () => {

    const [isLoading, setIsLoading] = useState(true)

    const params = useParams()
    const { quill, quillRef } = useQuill({
        readOnly: true,
        modules: {
            toolbar: false
        }
    })

    const [resultado, setResultado] = useState({})

    const fetching = async () => {
        let api = `https://backend-blog-api.vercel.app/api/articulos/${params.id}`
        const res = await fetch(api)
        const resultados = await res.json()
        setResultado(resultados)
        setIsLoading(false)
        quill.setContents(JSON.parse(resultados.contenido))
    }

    const getEdad = (dateString) => {
        let dateNacimiento = new Date(dateString)
        let day = dateNacimiento.getDate()
        let year = dateNacimiento.getFullYear()
        let month = dateNacimiento.getMonth()
        return `${day}/${month}/${year}`
    }

    useEffect(() => {
        if (quill) {
            fetching()
        }
    }, [params.id, quill])

    return (
        <section className='container px-5 py-4'>
            {
                isLoading ? <div className='d-flex justify-content-center'>
                    <div className='spinner'></div>
                </div> : ""
            }
            <h1 className='text-center fw-bold'>{resultado.titulo}</h1>
            {
                isLoading ? "" : <p className='text-center text-muted'><em>Publicado el {getEdad(resultado.date)}</em></p>
            }
            <div ref={quillRef} className="border-0"></div>
        </section>
    )
}

export default ArticleView