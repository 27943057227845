import {HashRouter, Routes, Route} from "react-router-dom"
import Home from "../../pages/Home/Home"
import Article from "../../pages/Article/Article"
import Navbar from "../navbar/Navbar"
import Footer from "../footer/Footer"
import ArticleView from "../../pages/Article/ArticleView"

const Rutas = () => {
  return (
    <HashRouter>
    <Navbar/>
        <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route path="/Articulos" element={<Article/>} />
            <Route path="/Articulos/:id" element={<ArticleView/>} />
        </Routes>
        <Footer />
    </HashRouter>
  )
}

export default Rutas