import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom"
import "./cards.css"

const Cards = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [resultado, setResultado] = useState([])
    const location = useLocation()

    const fetching = async () => {
        let api = `https://backend-blog-api.vercel.app/api/articulos`
        const res = await fetch(api)
        const resultados = await res.json()
        setResultado(resultados)
        setIsLoading(false)
    }

    const getEdad = (dateString) => {
        let dateNacimiento = new Date(dateString)
        let day = dateNacimiento.getDate()
        let year = dateNacimiento.getFullYear()
        let month = dateNacimiento.getMonth() + 1

        return `${day}/${month}/${year}`
    }

    useEffect(() => {
        fetching()
    }, [])
    return (
        <section className='articulos'>
            <h2 className='articulos-titulo'>Mis articulos📝</h2>
            {
                isLoading ? <div className='d-flex justify-content-center'>
                    <div className='spinner'></div>
                </div> : ""
            }
            <div className="d-flex flex-column flex-md-row gap-2">
                {
                    resultado.map(articulo => (
                        <div className="card d-flex justify-content-between" style={{ width: '18rem' }} key={articulo._id}>
                            <div className="card-body">
                                <img src={articulo.imagen} className="imagen" height={150}/>
                                <div className="">
                                    <h3 className='fw-bold'>{articulo.titulo}</h3>
                                    <p className=''>{articulo.resumen}</p>
                                </div>
                            </div>
                            <div className="form-control border-0 card-footer">
                                <Link to={`/Articulos/${articulo._id}`} className='btn btn-dark form-control'>Ver mas</Link>
                                <p className='text-muted mt-1 mb-0 text-time'><em>Publicado: {getEdad(articulo.date)}</em></p>
                            </div>
                        </div>
                    ))
                }
            </div>
            {
                location.pathname.includes("Articulos") ? null : (<Link to="/Articulos" className='btn btn-secondary mt-2 '>Ver todas las entradas</Link>)
            }

        </section>)
}

export default Cards