import mariano0 from "../img/0.jpg"
import mariano1 from "../img/1.jpg"
import mariano2 from "../img/2.jpg"
import mariano3 from "../img/3.jpg"
import mariano4 from "../img/4.jpg"
import mariano5 from "../img/5.jpg"

export default{
    "mariano0": mariano0,
    "mariano1": mariano1,
    "mariano2": mariano2,
    "mariano3": mariano3,
    "mariano4": mariano4,
    "mariano5": mariano5
}